import { LogoV3 } from "components/ui/Icons";
import { CrossIcon } from "components/v3/icons";
import { MonoText, Tag } from "components/v3/shared";
import { set } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "utils/common";

const Container = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;

  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.09);

  // first item border radius
  & > a:first-child {
    border-radius: 8px 0px 0px 8px;
    border-left: none;

    // logo font
    font-size: 14px;
    padding: 10px 22px;
  }

  // last item border radius
  & > a:last-child {
    border-radius: 0px 8px 8px 0px;
    border-right: none;
  }

  display: flex;
  z-index: 3;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: none;
  }
`;

const NavItem = styled.a`
  z-index: 1;
  padding: 14px 22px;
  gap: 10px;
  border: 0px 0px 0px 1px;
  opacity: 0px;
  background-color: white;
  white-space: nowrap;
  cursor: pointer;

  font-family: "Martian Mono", monospace;
  font-family: Martian Mono;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  text-align: center;
  text-decoration: none;
  color: ${props => (props.isActive ? "#1473DF" : "#1F1F1F")};

  border-left: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;

  display: flex;
  align-items: center;
  gap: 10px;

  :hover {
    background-color: #ebebeb;
    color: #1473df;
  }

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    background-color: transparent;
    border: none;
    padding: 14px 10px;
  }
`;

const SmallTag = styled.a`
  display: block;
  font-family: Martian Mono;
  border: 1px solid #ffffff;
  border-radius: 6px;
  width: max-content;
  background-color: ${props => props?.color || "#1473df"};
  color: #ffffff;
  font-size: 10px;
  padding: 4px;
`;

const LINK_GROUPS = [
  {
    title: "Flow",
    color: "#9650ff",
    subTitle: "Individuals & small teams",
    description: "No code LLLM solutions for individuals and small teams.",
    links: [
      { title: "Chat", href: "/product" },
      { title: "Space", href: "/product" },
      { title: "Apps", href: "/product" },
    ],
  },
  {
    title: "AppGen",
    subTitle: "SMEs & developers",
    color: "#009933",
    description: "LLM agentic software developer for SME.",
    links: [
      { title: "CRM", href: "/code-gen" },
      { title: "Trading System", href: "/code-gen" },
      { title: "E-Commerce", href: "/code-gen" },
    ],
  },
  {
    title: "API",
    description: "Customised agentic LLM APIs for developers.",
    links: [
      { title: "Chat API", href: "/api" },
      { title: "Analytics API", href: "/api" },
      { title: "Workflow API", href: "/api" },
      { title: "Data Extraction API", href: "/api" },
      { title: "Fine-tuning API", href: "/api" },
    ],
  },
  {
    title: "Enterprise Solution",
    subTitle: "For enterprise",
    color: "#1473df",
    description: "Enterprise proprietary LLM solutions for organisations.",
    links: [
      { title: "Data Extraction", href: "/solutions" },
      { title: "Analytics", href: "/solutions" },
      { title: "Workflow Automation", href: "/solutions" },
    ],
  },
  {
    title: "Financial Services",
    description: "Task specfic GenAI solution for verticals.",
    links: [
      { title: "Sell-Side", href: "/solutions" },
      { title: "Buy-Side", href: "/solutions" },
      { title: "Hedge-Funds", href: "/solutions" },
      { title: "FinTech", href: "/solutions" },
    ],
  },
];

const FullWidthTip = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  /* background-color: white; */
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  transition: opacity 0.4s;

  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(20px);
`;

const Groups = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 40px;

  margin-top: 54px;
  padding: 40px;
`;

const Group = styled.div`
  display: grid;
  gap: 30px;
  align-content: start;
  max-width: 200px;
`;

const GroupAnchor = styled.a`
  text-decoration: none;
  color: #1f1f1f;
  width: max-content;
  font-size: 14px;

  :hover {
    color: #1473df;
  }
`;

const RainbowText = styled.span`
  background: linear-gradient(90deg, #30c77b 0%, #42968d 34%, #4c63c8 67.5%, #e14649 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  line-height: 1.5;
`;

const VerticalLine = styled.div`
  width: 1px;
  background-color: #dedede;
  height: 100%;
`;

const BlackRect = styled.div`
  background-color: #000000;
  width: 14px;
  height: 14px;
`;

const MobileTrigger = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1;

  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 15.2px -3px #00000040;
`;

const MobileNav = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #f6f6f6;
  width: calc(100vw - 40px);
  box-shadow: 0px 4px 15.2px -3px #00000040;
  border-radius: 8px;
  z-index: 10;

  /* ${NavItem} {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  } */
`;

const MobileTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  padding: 10px;
  align-items: center;

  font-family: Martian Mono;
  font-size: 12px;
`;

const CrossContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 17px;
  height: 17px;

  background-color: #000000;

  svg {
    height: 9px;
    width: 9px;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  cursor: pointer;
  :hover {
    fill: #1473df;
  }
`;

const BoldSpan = styled.span`
  font-weight: 500;
`;

const NavFloating = () => {
  const [isTipVisible, setIsTipVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let tipStyle = {
    opacity: isTipVisible ? 1 : 0,
    pointerEvents: isTipVisible ? "all" : "none",
  };

  if (isMobile()) {
    return (
      <>
        <MobileTrigger onClick={() => setIsOpen(true)}>
          <BlackRect />
        </MobileTrigger>
        {isOpen && (
          <MobileNav>
            <MobileTopBar>
              <BoldSpan>Menu</BoldSpan>
              <CrossContainer onClick={() => setIsOpen(false)}>
                <CrossIcon style={{ fill: "#ffffff" }} />
              </CrossContainer>
            </MobileTopBar>
            <NavItem isActive={window?.location?.pathname === "/"} href="/">
              Home
            </NavItem>
            <NavItem isActive={window?.location?.pathname?.includes("/about-us")} href="/about-us">
              About us
            </NavItem>
            <NavItem isActive={window?.location?.pathname?.includes("/solutions")} href="/solutions">
              Solutions
            </NavItem>
          </MobileNav>
        )}
      </>
    );
  }

  return (
    <>
      <Container>
        <NavItem href="/">
          <LogoV3 />
          BOLTZBIT
        </NavItem>
        <NavItem isActive={window?.location?.pathname?.includes("/about-us")} href="/about-us">
          About us
        </NavItem>
        <NavItem isActive={window?.location?.pathname?.includes("/research")} href="/research">
          Research
        </NavItem>
        <NavItem
          isActive={window?.location?.pathname?.includes("/product") || isTipVisible}
          onClick={() => setIsTipVisible(prev => !prev)}
        >
          Product
        </NavItem>
        <NavItem isActive={window?.location?.pathname?.includes("/solutions")} href="/solutions">
          Solutions
        </NavItem>
        <NavItem isActive={window?.location?.pathname?.includes("/blog")} href="/blogs">
          Blogs
        </NavItem>
        <NavItem target="_blank" href="https://flow.boltzbit.com/login">
          <RainbowText>Login</RainbowText>
        </NavItem>
        <NavItem target="_blank" href="https://flow.boltzbit.com">
          <RainbowText>Free chat</RainbowText>
        </NavItem>
      </Container>
      <FullWidthTip style={tipStyle} onClick={() => setIsTipVisible(false)}>
        <Groups>
          {LINK_GROUPS.map((group, index) => {
            let isLineVisible = [0, 2]?.includes(index);

            return (
              <>
                <Group key={index}>
                  {group?.subTitle ? (
                    <SmallTag color={group?.color}>{group?.subTitle}</SmallTag>
                  ) : (
                    <div style={{ height: 20 }} />
                  )}
                  <MonoText onClick={e => e.stopPropagation()} style={{ fontWeight: 600 }}>
                    {group.title}
                  </MonoText>
                  <MonoText onClick={e => e.stopPropagation()} style={{ fontSize: 12, height: 70 }}>
                    {group.description}
                  </MonoText>
                  {group.links.map((link, index) => (
                    <GroupAnchor key={index} href={link.href}>
                      {link.title}
                    </GroupAnchor>
                  ))}
                </Group>
                {isLineVisible && <VerticalLine />}
              </>
            );
          })}
        </Groups>

        <StyledCrossIcon
          style={{
            position: "fixed",
            top: 40,
            right: 40,
          }}
        />
      </FullWidthTip>
    </>
  );
};

export default NavFloating;
