import zIndex from "@material-ui/core/styles/zIndex";
import { ICON_STR_TO_COMPONENT } from "api/backend/solutions";
import { Gap } from "components/Layout";
import { DownShapeIcon } from "components/v3/icons";
import { SubTitle, Tag } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  justify-content: center;
  background-color: #f6f6f6;
  padding-bottom: 120px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 20px;
  }
`;

const ColumnRect = styled.div`
  position: relative;
  padding: 0px;
  line-height: 1.2;
  background-color: #ebebeb;
  color: #1f1f1f;
  padding: 35px;
`;

const ColumnsContainer = styled.div`
  max-width: 1140px;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 45px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: flex;
    width: 100%;
    overflow: auto;
    flex-wrap: wrap;
  }
`;

const DOWN_STYLE = {
  position: "absolute",
  top: 0,
  right: 0,
  fill: "#ffffff",
  width: 78,
};

const Cards = ({ cards = [], title = "" }) => {
  return (
    <Container>
      <ColumnsContainer>
        <SubTitle dangerouslySetInnerHTML={{ __html: title }} />
        <Gap height="60px" />
        <Columns>
          {cards?.map((column, index) => {
            return (
              <ColumnRect key={index}>
                <DownShapeIcon style={DOWN_STYLE} />
                {ICON_STR_TO_COMPONENT?.[column.icon]}
                <Gap height="10px" />
                <SubTitle style={{ fontSize: 20 }}>{column.title}</SubTitle>
                <Gap height="20px" />
                {column?.description}
              </ColumnRect>
            );
          })}
        </Columns>
      </ColumnsContainer>
    </Container>
  );
};

export default Cards;
