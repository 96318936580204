import { apiGet, apiPost } from "api/api-http-methods";
import { zapiGet, zapiPost } from "api/zapi";

export const postContactUsDetails = async body => {
  const { data, error } = await zapiPost("/solutions/marketing/api/v1/contact-us", null, body);
  return { data, error };
};

export const postNewsletterEmail = async body => {
  const { data, error } = await zapiPost("/solutions/marketing/api/v1/newsletters", null, body);
  return { data, error };
};

export const getWebsiteContents = async params => {
  const { data, error } = await zapiGet("/solutions/marketing/api/v1/website-contents", params);
  return { data, error };
};

export const getWebsiteContentsById = async (id, params) => {
  const { data, error } = await zapiGet(`/solutions/marketing/api/v1/website-contents/${id}`, params);
  return { data, error };
};

export const getWebsiteContentsVersion = async (version = "latest", params) => {
  const { data, error } = await zapiGet(`/solutions/marketing/api/v1/website-contents/${version}`, params);
  return { data, error };
};

export const postWebsiteContents = async (params, body) => {
  const { data, error } = await zapiPost("/solutions/marketing/api/v1/website-contents", params, body);
  return { data, error };
};

export const postUploadFiles = async (params, body, onUploadProgress = () => {}) => {
  const { data, error } = await zapiPost("/solutions/marketing/api/v1/upload-files", params, body, onUploadProgress);
  return { data, error };
};

export const postUrlTokensVerify = async (params = {}, body = {}) => {
  const { data, error } = await apiPost("/solutions/marketing/api/v1/url-tokens/verify", params, body);
  return { data, error };
};

export const postSlideTracking = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost("/solutions/marketing/api/v1/slide-tracking", params, body);
  return { data, error };
};

export const getConfirmEmail = async (accessToken = "", params = {}) => {
  const { data, error } = await zapiGet(`/solutions/marketing/api/v1/confirm-email/${accessToken}`, params);
  return { data, error };
};

export const getUnsubscribe = async (params = {}) => {
  const { data, error } = await zapiGet("/solutions/marketing/api/v1/unsubscribe", params);
  return { data, error };
};

export const postSurvey = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost("/solutions/marketing/api/v1/survey", params, body);
  return { data, error };
};

export const getFlowDatabaseTemplates = async (params = {}) => {
  const { data, error } = await zapiGet("/solutions/ocr/chat-service/api/v1/flow-databases-templates", params);
  return { data, error };
};

export const getBlogs = async (params = {}) => {
  const { data, error } = await zapiGet("/solutions/marketing/api/v1/blogs", params);
  return { data, error };
};

export const getBlog = async (id = "", params = {}) => {
  const { data, error } = await zapiGet(`/solutions/marketing/api/v1/blogs/${id}`, params);
  return { data, error };
};

export const postPageVisit = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost("/solutions/marketing/api/v1/page-visits", params, body);
  return { data, error };
};
