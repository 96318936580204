import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Gap } from "components/Layout";
import { MonoText, Tag, Title } from "components/v3/shared";

import ThreeColumns from "components/solutions-sections/ThreeColumns";
import TwoTaggedColumns from "components/solutions-sections/TwoTaggedColumns";
import Cards from "components/solutions-sections/Cards";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { getWebsiteContentsById } from "api/backend/marketingServiceEndpoints";
import { useEffect } from "react";

const SIDE_MARGIN = 150;

const Container = styled.div`
  display: grid;
  padding-left: ${SIDE_MARGIN}px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 120px 150px;
  background-color: #1473df;
  color: #ffffff;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 120px 20px;
  }
`;

const SolutionContent = () => {
  const { id } = useParams();

  const [contentItem, setContentItem] = useState({});
  const [status, setStatus] = useState("FETCHING");

  useEffect(() => {
    doPopulateContent();
  }, [id]);

  const doPopulateContent = async () => {
    const { data } = await getWebsiteContentsById(id);
    setContentItem(data);
    setStatus("READY");
  };

  const solution = contentItem?.jsonContent || {};
  const { title, subTitle, headerTag, contentBlocks } = solution || {};

  if (status === "FETCHING") {
    return (
      <Container>
        <CircularProgress thickness={10} style={{ color: "#1473df" }} />
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Tag>{headerTag}</Tag>
        <Gap height="40px" />
        <Title
          style={{ maxWidth: 800, textAlign: "center", lineHeight: 1.2 }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Gap height="40px" />
        <MonoText
          style={{ textAlign: "center", maxWidth: 600, color: "#ffffff" }}
          dangerouslySetInnerHTML={{ __html: subTitle }}
        />
      </Container>
      {contentBlocks?.map((block, index) => {
        if (block.type === "two-tagged-columns") {
          return <TwoTaggedColumns key={index} {...block} />;
        }
        if (block?.type === "three-columns") {
          return <ThreeColumns key={index} {...block} />;
        }
        if (block?.type === "cards") {
          return <Cards key={index} {...block} />;
        }
        return null;
      })}
    </>
  );
};

export default SolutionContent;
