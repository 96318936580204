import { Gap } from "components/Layout";
import { DownShapeIcon, LeftExtandedShapeIcon, RightShapeIcon } from "components/v3/icons";
import { SubTitle, Tag } from "components/v3/shared";
import { useState } from "react";
import styled from "styled-components";
import { isMobile } from "utils/common";

const Container = styled.div`
  position: relative;
  background-color: #1f1f1f;
  color: #ffffff;
  min-height: 100vh;

  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  padding: 0 150px;

  /* @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: none;
  } */
`;

const MobileContainer = styled.div`
  position: relative;
  background-color: #1f1f1f;
  color: #ffffff;
  min-height: 100vh;

  display: grid;
  align-content: start;
  grid-template-columns: 1fr;
  padding: 20px;
  padding-top: 80px;
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div`
  position: relative;
  video {
    height: 250px;
  }
`;

const OptionContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
`;

const Selectors = styled.div`
  width: 400px;
  align-self: bottom;
`;

const SelectorOption = styled.div`
  border-top: 1px solid white;
  font-family: Martian Mono;
  font-size: 19px;
  font-weight: 300;
  padding: 20px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  height: 80px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.selected ? "#1c3750" : "transparent")};

  svg {
    fill: ${props => (props.selected ? "#157FE3" : "#ffffff")};
  }

  :hover {
    background-color: #1c3750;
  }

  :last-child {
    border-bottom: 1px solid white;
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 400px;
`;

const StyledTag = styled(Tag)`
  cursor: pointer;
  width: 180px;
  text-align: center;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    width: auto;
  }
`;

const MobileOptions = styled.div`
  display: flex;
  gap: 40px;
  overflow: auto;
`;

const MobileOptionContent = styled.div`
  font-family: Martian Mono;
  font-size: 14px;
  font-weight: 300;

  svg {
    width: 240px;
    height: 240px;
  }
`;

const DOWN_STYLE = {
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  fill: "#EBEBEB",
};

const LEFT_STYLE = {
  position: "absolute",
  bottom: -26,
  right: 223,
  fill: "#1473DF",
};

const SelectorSectionV3 = ({
  navTags = [],
  title,
  description,
  options,
  options1 = [],
  options2 = [],
  options3 = [],
  options4 = [],
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [optionsIndex, setOptionsIndex] = useState(0);

  const allOptions = [options, options1, options2, options3, options4];
  const selectedOptions = allOptions?.[optionsIndex];

  const onClickTag = i => {
    if (i === optionsIndex) {
      setOptionsIndex(0);
      return;
    }

    setOptionsIndex(i);
  };

  const navigationComponent = (
    <Tags>
      {navTags.map((tag, i) => {
        if (i === 0) {
          return null;
        }

        return (
          <StyledTag color={i === optionsIndex ? "#1473DF" : "#ffffff"} onClick={() => onClickTag(i)} key={i}>
            {tag}
          </StyledTag>
        );
      })}
    </Tags>
  );

  if (isMobile()) {
    return (
      <MobileContainer>
        <SubTitle>{title}</SubTitle>
        <Gap height="10px" />
        <div>{description}</div>
        <Gap height="20px" />
        {navigationComponent}
        <Gap height="40px" />
        <MobileOptions>
          {selectedOptions?.map((option, i) => (
            <MobileOptionContent>
              <div style={{ display: "grid", gap: 10, alignItems: "center" }}>
                {option?.subTitle && <div style={{ fontSize: 10 }}>{option.subTitle}</div>}
                <div>{option.title}</div>
              </div>
              <Gap height="40px" />
              {option.content}
            </MobileOptionContent>
          ))}
        </MobileOptions>
        <Gap height="40px" />
      </MobileContainer>
    );
  }

  return (
    <Container>
      <DownShapeIcon style={DOWN_STYLE} />
      <LeftExtandedShapeIcon style={LEFT_STYLE} />
      <LeftColumn>
        <SubTitle>{title}</SubTitle>
        <Gap height="10px" />
        <div>{description}</div>
        <Gap height="20px" />
        {navigationComponent}
        <Gap height="40px" />
        <Selectors>
          {selectedOptions.map((option, i) => (
            <SelectorOption selected={i === selectedOptionIndex} key={i} onClick={() => setSelectedOptionIndex(i)}>
              <div style={{ display: "grid", gap: 10, alignItems: "center" }}>
                {option?.subTitle && <div style={{ fontSize: 10 }}>{option.subTitle}</div>}
                <div>{option.title}</div>
              </div>
              <RightShapeIcon />
            </SelectorOption>
          ))}
        </Selectors>
      </LeftColumn>
      <RightColumn>
        {options?.map((option, i) => (
          <OptionContent style={{ visibility: i === selectedOptionIndex ? "visible" : "hidden" }}>
            {option.content}
          </OptionContent>
        ))}
      </RightColumn>
    </Container>
  );
};

export default SelectorSectionV3;
