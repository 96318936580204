import { ICON_STR_TO_COMPONENT } from "api/backend/solutions";
import { Gap } from "components/Layout";
import { SubTitle, Tag } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  justify-content: center;
  background-color: #f6f6f6;
  padding-bottom: 120px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding-bottom: 20px;
  }
`;

const ColumnRect = styled.div`
  padding: 0px;
  max-width: 440px;
  line-height: 1.5;
  color: #ffffff;
  padding: 0 40px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    width: 70vw;
  }
`;

const ColumnsContainer = styled.div`
  background-color: #1f1f1f;
  max-width: 1140px;
  padding: 120px 60px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 40px 20px;
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${ColumnRect} {
    border-right: 1px solid #ffffff;
  }

  ${ColumnRect}:last-child {
    border-right: none;
  }

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: grid;
    width: calc(100vw - 40px);
    overflow: auto;
    padding-bottom: 20px;
  }
`;

const ThreeColumns = ({ columns = [], tag = "" }) => {
  return (
    <Container>
      <ColumnsContainer>
        <Tag style={{ marginLeft: 40 }} color="#ffffff">
          {tag}
        </Tag>
        <Gap height="40px" />
        <Columns>
          {columns?.map((column, index) => {
            return (
              <ColumnRect key={index}>
                {ICON_STR_TO_COMPONENT?.[column.icon]}
                <Gap height="10px" />
                <SubTitle style={{ fontSize: 20 }}>{column.title}</SubTitle>
                <Gap height="20px" />
                {column?.description}
              </ColumnRect>
            );
          })}
        </Columns>
      </ColumnsContainer>
    </Container>
  );
};

export default ThreeColumns;
