import { BLOG1_TEXT } from "api/backend/blog1";
import { BLOG2_TEXT } from "api/backend/blog2";
import {
  BrainIcon,
  CycleIcon,
  LightningIcon,
  LockIcon,
  MoneyBagIcon,
  PencilRulerIcon,
  SpeedometerIcon,
  TargetIcon,
  TrendUpIcon,
} from "components/v3/icons";

export const FEATURED_ID = 0;

export const ICON_STR_TO_COMPONENT = {
  speedometer: <SpeedometerIcon />,
  target: <TargetIcon />,
  trendup: <TrendUpIcon />,
  pencilruler: <PencilRulerIcon />,
  lock: <LockIcon />,
  cycle: <CycleIcon />,
  moneybag: <MoneyBagIcon />,
  brain: <BrainIcon />,
  lightning: <LightningIcon />,
};

const S0 = {
  id: 0,
  type: "solution",
  logoSrc: "/images/logos/tpicap-logo.png",
  title: "Transforming bond data processing for <span class='black-highlight'>TP ICAP</span>",
  subTitle: "An AI-driven solution streamlining complex data extraction and boosting operational efficiency.",
  description:
    "Liquidnet, a leading technology-driven agency execution specialist, today announced its partnership with cutting edge, GenAI startup Boltzbit to optimise its Fixed Income primary markets workflow.",
  dateStr: "2023-10-10",
  headerTag: "CASE STUDY",
  tags: ["AI", "LLM", "Custom AI"],
  contentBlocks: [
    {
      type: "two-tagged-columns",
      columns: [
        {
          tag: "THE PROBLEM",
          title: "Manual bond data processing slows trading",
          description:
            "Manual processing of bond data from emails to trading systems was a time-consuming task for TP ICAP, slowing down their ability to act on new bond opportunities. This manual approach was not only inefficient but also increased the risk of human error, impacting the speed and accuracy of trading decisions.",
        },
        {
          tag: "THE SOLUTION",
          title: "AI-powered automation for instant data ingestion",
          description:
            "Boltzbit implemented a fully proprietary LLM solution that automates the ingestion of bond data from emails directly into the trading system within seconds. This AI-driven approach significantly reduced processing time, enhanced data accuracy, and streamlined operations, allowing TP ICAP to act swiftly on new trading opportunities.",
        },
      ],
    },
    {
      type: "three-columns",
      tag: "IMPACT",
      columns: [
        {
          icon: "speedometer",
          title: "Increased speed",
          description:
            "Automated data ingestion reduced processing time from minutes to seconds, enabling faster decision-making.",
        },
        {
          icon: "target",
          title: "Increased accuracy",
          description:
            "The LLM’s high precision minimized human error, boosting data reliability in the trading system.",
        },
        {
          icon: "trendup",
          title: "Higher revenue",
          description:
            "Faster, more accurate data processing led to a 15% increase in broker fees from primary bond issuance.",
        },
      ],
    },
    {
      type: "cards",
      title: "Boltzbit redefines custom LLM solutions",
      cards: [
        {
          icon: "pencilruler",
          title: "Tailored for specific needs",
          description:
            "Boltzbit’s custom LLMs are built to meet unique business requirements, focusing on precision and task-specific accuracy. Unlike general-purpose models, Boltzbit’s solutions are crafted to master particular tasks, ensuring unmatched accuracy and relevance for each client.",
        },
        {
          icon: "lock",
          title: "Client-owned IP",
          description:
            "Boltzbit’s approach empowers clients with proprietary IP, making them independent from third-party models. This ownership not only offers control over the technology but also enhances data privacy and security for the client’s specific use cases.",
        },
        {
          icon: "cycle",
          title: "Flexible integration",
          description:
            "Boltzbit’s solutions come with adaptable interfaces that integrate seamlessly into various workflows. This flexibility allows businesses to easily adopt the technology without overhauling existing systems, making it a practical choice across industries.",
        },
        {
          icon: "moneybag",
          title: "Cost-efficiency with scalability",
          description:
            "Through a proprietary architecture, Boltzbit offers highly scalable and cost-effective custom LLM solutions. This approach ensures that as business needs grow, the model can scale without requiring exponential cost increases, making it sustainable for long-term use.",
        },
        {
          icon: "brain",
          title: "Reduced hallucination with MegaToken tech",
          description:
            "Boltzbit’s MegaToken LLM technology addresses the issue of hallucination by reducing the model’s reliance on token-by-token predictions. This advancement enhances accuracy and reliability, especially in critical applications where precision is key.",
        },
        {
          icon: "lightning",
          title: "Accelerated results",
          description:
            "Boltzbit’s custom LLMs deliver rapid results, transforming workflows by reducing manual processing times. This speed allows businesses to react to market changes and client needs faster, improving overall efficiency and responsiveness.",
        },
      ],
    },
  ],
};

const S1 = {
  id: 1,
  thumbnailSrc: "/images/blogs/innovate-uk.jpg",
  title: "Boltzbit Kicks Off Innovate UK Project",
  description:
    "We're thrilled to announce the beginning of an exciting new chapter at Boltzbit! Starting April 1st, Boltzbit has embarked on a groundbreaking project, funded by Innovate UK Partnerships, aimed at revolutionizing business analytics and workflow automation. This milestone marks a significant step forward in our mission to innovate and deliver cutting-edge solutions for businesses worldwide.",
  dateStr: "2023-04-03",
  tags: ["AI", "LLM", "Custom AI"],
  author: "BOLTZBIT TEAM",
  contentBlocks: [
    {
      text: BLOG1_TEXT,
    },
  ],
};

const S2 = {
  id: 2,
  thumbnailSrc: "/images/blogs/fin-tech-lab.jpg",
  title: "Boltzbit Selected as One of the FinTechs for the FinTech Innovation Lab London 2024",
  description:
    "We are thrilled to announce that Boltzbit has been chosen as one of the 15 new FinTechs for the FinTech Innovation Lab London 2024. This prestigious program, run by the FinTech Innovation Lab, recognizes innovative companies that are transforming the financial industry through cutting-edge technology.",
  dateStr: "2023-10-10",
  tags: ["AI", "LLM", "Custom AI"],
  author: "BOLTZBIT TEAM",
  contentBlocks: [
    {
      text: BLOG2_TEXT,
    },
  ],
};

export const SOLUTIONS = [S0];
