import { CircularProgress } from "@material-ui/core";
import { getWebsiteContents } from "api/backend/marketingServiceEndpoints";
import { Gap } from "components/Layout";
import { MonoText, SubTitle, Tag } from "components/v3/shared";
import { format } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { safeFormat } from "utils/common";

const SIDE_MARGIN = 150;

const Container = styled.div`
  display: grid;
  padding-left: ${SIDE_MARGIN}px;
  justify-content: center;
  align-items: center;
  padding: 120px 150px;
  background-color: #ebebeb;
  color: #1f1f1f;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 80px 20px;
  }
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffffff;
`;

const FlexItems = styled.div`
  display: flex;
  gap: 10px;
`;

const Card = styled.div`
  display: grid;
  padding: 40px;
  background-color: #ffffff;
  gap: 20px;
  line-height: 1.2;
  height: max-content;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 10px;
  }
`;

const BlogDescription = styled.div`
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    font-size: 12px;
  }
`;

const VerticalItems = styled.div`
  display: grid;
  gap: 10px;
  align-content: start;
  height: max-content;
`;

const ReadMoreLink = styled.a`
  color: #1473df;
  font-family: Martian Mono;
  width: max-content;
  font-size: 14px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    font-size: 11px;
  }
`;

const BlogCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  width: 1140px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    width: auto;
    grid-template-columns: 1fr;
  }
`;
const StyledMonoText = styled(MonoText)`
  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    font-size: 11px;
  }
`;

const LogoImg = styled.img`
  height: 60px;
  object-fit: cover;
`;

const SolutionCard = ({ id = "", solution = {} }) => {
  const date = new Date(solution?.dateStr);
  const formattedDate = safeFormat(date, "MMMM dd, yyyy");

  return (
    <Card>
      <VerticalItems>
        {solution.logoSrc && <LogoImg src={solution.logoSrc} style={{ marginBottom: 10 }} />}

        <StyledMonoText>{formattedDate}</StyledMonoText>
        <SubTitle style={{ fontSize: 20 }} dangerouslySetInnerHTML={{ __html: solution.title }} />
        <BlogDescription>{solution.description}</BlogDescription>
        <Gap height="0px" />
        <ReadMoreLink href={`/solutions/${id}`}>Read case study</ReadMoreLink>
        <Gap height="10px" />
        <HorizontalLine style={{ backgroundColor: "#EBEBEB" }} />
        <Gap height="10px" />
        <FlexItems>
          {solution.tags.map((tag, i) => (
            <Tag color="#666666" key={i}>
              {tag}
            </Tag>
          ))}
        </FlexItems>
      </VerticalItems>
    </Card>
  );
};

// status = FETCHING, READY
const SolutionsList = () => {
  const [contentItems, setContentItems] = useState([]);
  const [status, setStatus] = useState("FETCHING");

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getWebsiteContents();
    setContentItems(data || []);
    setStatus("READY");
  };

  const solutions = contentItems?.filter(item => item?.jsonContent?.type === "solution");

  if (status === "FETCHING") {
    return (
      <Container>
        <BlogCards>
          <CircularProgress thickness={10} style={{ color: "#1473df" }} />
        </BlogCards>
      </Container>
    );
  }

  return (
    <Container>
      <BlogCards>
        {solutions.map(solution => (
          <SolutionCard key={solution?.id} id={solution?.id} solution={solution?.jsonContent} />
        ))}
      </BlogCards>
    </Container>
  );
};

export default SolutionsList;
