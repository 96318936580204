import { format } from "date-fns";

export const getCookieByName = cookieName => {
  try {
    const matches = document.cookie.matchAll(new RegExp("(^| )" + cookieName + "=([^;]+)", "g"));
    const matchedValues = Array.from(matches).map(match => match[2]);

    if (matchedValues.length > 1) {
      return matchedValues?.find(value => value !== "PUBLIC") || "";
    }

    return matchedValues[0] || "";
  } catch (e) {
    return "";
  }
};

export const deleteAllCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const formatSafe = (dateStr, formatStr) => {
  try {
    return format(new Date(dateStr), formatStr);
  } catch (e) {
    return dateStr;
  }
};

export const dateSafe = dateStr => {
  try {
    return new Date(dateStr);
  } catch (e) {
    return dateStr;
  }
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export function urlParamsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export const isMobile = () => {
  return window.innerWidth < 1000;
};

export const safeFormat = (date, formatString) => {
  try {
    return format(new Date(date), formatString);
  } catch {
    return "";
  }
};
