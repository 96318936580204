import { CircularProgress } from "@material-ui/core";
import { getWebsiteContents } from "api/backend/marketingServiceEndpoints";
import { Gap } from "components/Layout";
import { ButtonLink, MonoText, SubTitle, Tag } from "components/v3/shared";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { safeFormat } from "utils/common";

const SIDE_MARGIN = 150;

const Container = styled.div`
  display: grid;
  padding-left: ${SIDE_MARGIN}px;
  justify-content: center;
  align-items: center;
  padding: 120px 150px;
  background-color: #ebebeb;
  color: #1f1f1f;
`;

const ContainerFeatured = styled.div`
  padding: 40px;
  background-color: #157fe3;
  line-height: 1.5;
  max-width: 1140px;

  color: #ffffff;
  * {
    color: #ffffff;
  }
`;

const ImgFeatured = styled.img`
  width: 100%;
  height: 230px;
  object-fit: cover;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffffff;
`;

const FlexItems = styled.div`
  display: flex;
  gap: 10px;
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: 256px 1fr;
  padding: 40px;
  background-color: #ffffff;
  gap: 20px;
  line-height: 1.2;
`;

const BlogDescription = styled.div`
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImgCard = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VerticalItems = styled.div`
  display: grid;
  gap: 20px;
  align-content: start;
`;

const ReadMoreLink = styled.a`
  color: #1473df;
  font-family: Martian Mono;
  width: max-content;
  font-size: 12px;
`;

const BlogCards = styled.div`
  display: grid;
  gap: 40px;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 68px;
  max-width: 1140px;
`;

const BlogsNavCard = styled.div`
  display: grid;
  padding: 20px;
  background-color: #ffffff;
  align-content: start;
  height: max-content;
  gap: 40px;
`;

const BlogNavItem = styled.div`
  font-size: 13px;
  color: #1f1f1f;
  cursor: pointer;
  width: max-content;
  ${props => props?.isHighlighted && "color: #1473df;"}
`;

const StyledTag = styled(Tag)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }

  ${props =>
    props?.isHighlighted &&
    `
    color: #1473df;
    background-color: #ffffff;  
  `}
`;

const BLOGS_NAV = [
  {
    title: "Blog topics",
    options: [
      // { label: "Breaking news" },
      // { label: "Company" },
      // { label: "Technology" },
      // { label: "Artificial intelligence" },
      // { label: "Programming" },
      // { label: "Work" },
    ],
  },
  // {
  //   title: "Guide and tools",
  //   options: [{ label: "Guidelines" }, { label: "Tutorial" }, { label: "Training" }, { label: "Career" }],
  // },
];

const BlogCard = ({ id, blog, onClickFilterTag = () => {}, filterTag = "" }) => {
  const formattedDate = safeFormat(blog.dateStr, "MMMM dd, yyyy");

  return (
    <Card>
      <ImgCard src={blog.thumbnailSrc} />
      <VerticalItems>
        <MonoText>{formattedDate}</MonoText>
        <SubTitle style={{ fontSize: 20 }}>{blog.title}</SubTitle>
        <BlogDescription>{blog.description}</BlogDescription>
        <ReadMoreLink href={`/blogs/${id}`}>Read more</ReadMoreLink>
        <HorizontalLine style={{ backgroundColor: "#EBEBEB" }} />
        <FlexItems>
          {blog.tags.map((tag, i) => (
            <StyledTag color="#CCCCCC" key={i} onClick={() => onClickFilterTag(tag)} isHighlighted={tag === filterTag}>
              {tag}
            </StyledTag>
          ))}
        </FlexItems>
      </VerticalItems>
    </Card>
  );
};

const BlogsList = () => {
  const [contentItems, setContentItems] = useState([]);
  const [status, setStatus] = useState("FETCHING");
  const [filterTag, setFilterTag] = useState("");

  useEffect(() => {
    doPopulateContentItems();
  }, []);

  const doPopulateContentItems = async () => {
    const { data } = await getWebsiteContents();
    setContentItems(data || []);
    setStatus("READY");
  };

  const onClickFilterTag = tag => {
    if (filterTag === tag) {
      setFilterTag("");
      return;
    }
    setFilterTag(tag);
  };

  const blogs = contentItems?.filter(item => item?.jsonContent?.type === "blog");
  const filteredBlogs = blogs?.filter(item => item?.jsonContent?.tags.includes(filterTag) || !filterTag);

  const fBlog = filteredBlogs
    ?.filter(item => item?.jsonContent?.tags.includes(filterTag) || !filterTag)
    ?.find(blog => blog?.jsonContent?.isFeatured);

  const formattedDate = safeFormat(fBlog?.jsonContent?.dateStr, "MMMM dd, yyyy");
  const nonFeaturedBlogs = filteredBlogs?.filter(blog => !blog?.jsonContent?.isFeatured);
  const blogTopics = uniq(blogs?.map(blog => blog?.jsonContent?.tags).flat())?.map(label => ({ label })) || [];

  if (status === "FETCHING") {
    return (
      <Container>
        <BlogCards>
          <CircularProgress thickness={10} style={{ color: "#1473df" }} />
        </BlogCards>
      </Container>
    );
  }

  return (
    <Container>
      <ContainerFeatured style={{ display: fBlog ? "block" : "none" }}>
        <Tag>FEATURED BLOG</Tag>
        <Gap height="40px" />
        <ImgFeatured src={fBlog?.jsonContent?.thumbnailSrc} />
        <Gap />
        <MonoText>{formattedDate}</MonoText>
        <Gap />
        <SubTitle style={{ fontSize: 25 }}>{fBlog?.title}</SubTitle>
        <Gap />
        <div>{fBlog?.jsonContent?.description}</div>
        <Gap />
        <ButtonLink href={`/blogs/${fBlog?.id}`}>Read full story</ButtonLink>
        <Gap height="40px" />
        <HorizontalLine />
        <Gap height="40px" />
        <FlexItems>
          {fBlog?.jsonContent?.tags.map((tag, i) => (
            <StyledTag onClick={() => onClickFilterTag(tag)} key={i} isHighlighted={tag === filterTag}>
              {tag}
            </StyledTag>
          ))}
        </FlexItems>
      </ContainerFeatured>
      {fBlog && <Gap height="120px" />}
      <TwoColumns>
        <BlogsNavCard>
          {BLOGS_NAV.map((nav, i) => (
            <div key={i} style={{ display: "grid", gap: 10 }}>
              <MonoText style={{ color: "#1F1F1F" }}>{nav.title}</MonoText>
              <Gap height="0px" />
              {blogTopics.map((option, i) => (
                <BlogNavItem
                  onClick={() => onClickFilterTag(option?.label)}
                  key={i}
                  isHighlighted={option?.label === filterTag}
                >
                  {option.label}
                </BlogNavItem>
              ))}
            </div>
          ))}
        </BlogsNavCard>
        <BlogCards>
          {nonFeaturedBlogs.map(blog => (
            <BlogCard
              key={blog.id}
              id={blog?.id}
              blog={blog?.jsonContent}
              filterTag={filterTag}
              onClickFilterTag={onClickFilterTag}
            />
          ))}
        </BlogCards>
      </TwoColumns>
    </Container>
  );
};

export default BlogsList;
