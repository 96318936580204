import { Gap } from "components/Layout";
import { SubTitle, Tag } from "components/v3/shared";
import styled from "styled-components";

const TwoColsContainer = styled.div`
  display: grid;
  justify-content: center;
  background-color: #f6f6f6;
  padding: 120px 0;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 40px 0px;
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  max-width: 1140px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const ColumnRect = styled.div`
  padding: 40px;
  max-width: 440px;
  line-height: 1.2;
`;

const TwoTaggedColumns = ({ columns = [] }) => {
  return (
    <TwoColsContainer>
      <Columns>
        {columns?.map((column, index) => {
          let backgroundColor = "#ffffff";
          let color = "#1f1f1f";
          if (index === 1) {
            backgroundColor = "#1473DF";
            color = "#ffffff";
          }

          return (
            <ColumnRect key={index} style={{ backgroundColor, color }}>
              <Tag color={color}>{column?.tag}</Tag>
              <Gap />
              <SubTitle style={{ fontSize: 20 }}>{column.title}</SubTitle>
              <Gap height="20px" />
              {column?.description}
            </ColumnRect>
          );
        })}
      </Columns>
    </TwoColsContainer>
  );
};

export default TwoTaggedColumns;
