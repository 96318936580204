import { getWebsiteContentsById } from "api/backend/marketingServiceEndpoints";
import { Gap } from "components/Layout";
import { MonoText, Tag, Title } from "components/v3/shared";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { safeFormat } from "utils/common";

const SIDE_MARGIN = 150;

const Container = styled.div`
  display: grid;
  padding-left: ${SIDE_MARGIN}px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 120px 150px;
  background-color: #ffffff;
  color: #1f1f1f;
`;

const FlexItems = styled.div`
  display: flex;
  gap: 10px;
`;

const ImgThumbnail = styled.img`
  width: 1140px;
  max-height: 400px;
  object-fit: cover;
`;

const HtmlContent = styled.div`
  width: 740px;
  line-height: 1.5;

  h2 {
    font-family: Alexandria;
    font-size: 25px;
    font-weight: 700;
  }

  img {
    width: 100%;
    max-height: 370px;
    object-fit: cover;
  }

  .image-label {
    font-family: Martian Mono;
    font-size: 9px;
    font-weight: 300;
    color: #666666;
  }
`;

const BlogContent = () => {
  const { blogId } = useParams();

  const [contentItem, setContentItem] = useState({});
  const [status, setStatus] = useState("FETCHING");

  useEffect(() => {
    doPopulateContent();
  }, [blogId]);

  const doPopulateContent = async () => {
    const { data } = await getWebsiteContentsById(blogId);
    setContentItem(data);
    setStatus("READY");
  };

  const blog = contentItem?.jsonContent || {};
  const formattedDate = safeFormat(blog?.dateStr, "MMMM dd, yyyy");

  return (
    <Container>
      <Title style={{ textAlign: "center", lineHeight: 1.2 }}>{blog?.title}</Title>
      <Gap />
      <MonoText style={{ textAlign: "center", width: 600 }} dangerouslySetInnerHTML={{ __html: blog?.subTitle }} />
      <Gap height="60px" />
      <FlexItems>
        {blog?.tags?.map((tag, i) => (
          <Tag color="#666666" key={i}>
            {tag}
          </Tag>
        ))}
      </FlexItems>
      <Gap height="40px" />
      <div>
        <ImgThumbnail src={blog?.thumbnailSrc} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MonoText>BY {blog?.author?.toUpperCase()}</MonoText>
          <MonoText>{formattedDate}</MonoText>
        </div>
      </div>
      <Gap height="60px" />
      <MonoText style={{ width: 740 }} dangerouslySetInnerHTML={{ __html: blog?.description }} />
      {blog?.contentBlocks?.map((block, i) => {
        return <HtmlContent key={i} dangerouslySetInnerHTML={{ __html: block?.text }} />;
      })}
    </Container>
  );
};

export default BlogContent;
