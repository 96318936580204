import { Gap } from "components/Layout";
import { MonoText, SubTitle } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ebebeb;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 0px;
  padding: 90px 0;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 90px 20px;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  text-align: center;
  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    text-align: left;
  }
`;

const BannerV3 = ({ title = "", description = "" }) => {
  return (
    <Container>
      <StyledSubTitle style={{ maxWidth: 500 }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap height="20px" />
      <MonoText>{description}</MonoText>
    </Container>
  );
};

export default BannerV3;
