import { Gap } from "components/Layout";
import { GridGraphic } from "components/v3/graphics";
import { MonoText, SubTitle } from "components/v3/shared";
import { clamp, max, min } from "lodash";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { isMobile } from "utils/common";

let SCROLL_HEIGHT = 1000;
if (window?.innerHeight > 1000) {
  SCROLL_HEIGHT = 2000;
}

const mapValue = (domain = [], range = [], value) => {
  const [domainStart, domainEnd] = domain;
  const [rangeStart, rangeEnd] = range;

  const domainLength = domainEnd - domainStart;
  const rangeLength = rangeEnd - rangeStart;

  const domainValue = value - domainStart;
  const ratio = domainValue / domainLength;
  const rangeValue = rangeLength * ratio;
  const valueInRange = rangeStart + rangeValue;

  return clamp(valueInRange, min(range), max(range));
};

const LongContainer = styled.div`
  position: relative;
  height: ${SCROLL_HEIGHT}px;

  /* @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: none;
  } */
`;

const Container = styled.div`
  position: relative;
  background-color: #ebebeb;
  display: grid;
  grid-template-rows: 40px auto auto;
  justify-content: center;
  justify-items: center;
  align-items: start;
  gap: 40px;
  padding: 90px 0;
  width: 100%;
  height: 100vh;
`;

const MobileContainer = styled.div`
  position: relative;
  background-color: #ebebeb;
  padding: 20px;
  padding-top: 40px;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 920px;
  transition: opacity 0.2s;
  padding-bottom: 20px;

  & > :last-child {
    border-right: none;
  }

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    width: auto;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const Column = styled.div`
  display: grid;
  gap: 20px;
  border-right: 1px solid #1f1f1f;
  padding: 0 40px;
  line-height: 1.5;

  grid-template-rows: 42px auto auto;
  align-items: end;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    border-right: none;
    width: 260px;
    padding: 0px;
  }
`;

const ColumnTitle = styled.div`
  font-family: Alexandria;
  font-size: 19px;
  font-weight: 700;
  line-height: 19.95px;
  text-align: left;
`;

const StyledMonoText = styled(MonoText)`
  position: absolute;
  width: 164px;
`;

const StyledSpan = styled.span`
  transition: color 0.2s;
`;

const TOP_LEFT_TEXT = {
  left: "calc(50vw - 350px)",
  top: 245,
  color: "#1473DF",
};
const TOP_RIGHT_TEXT = {
  left: "calc(50vw + 180px)",
  top: 245,
  color: "#1F1F1F",
};
const BOTTOM_TEXT = {
  left: "calc(50vw - 60px)",
  top: 535,
  color: "#8C8C8C",
  width: 280,
};

const MessagesV3Animated = ({ title = "", messages = [] }) => {
  const [progressPercentage, setProgressPercentage] = useState(0);
  const containerRef = useRef();

  const onScroll = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    const newProgress = mapValue([0, -SCROLL_HEIGHT + window.innerHeight], [0, 100], rect.top);
    setProgressPercentage(newProgress);
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  const rect = containerRef.current?.getBoundingClientRect() || {};
  const { top } = rect;
  const hasReachedTop = top < 0;

  let bottomStyles = {
    top: 0,
  };
  if (progressPercentage >= 100) {
    bottomStyles = {
      position: "absolute",
      top: `calc(${SCROLL_HEIGHT}px - 100vh)`,
      height: "100vh",
    };
  }

  let textProgress = 1 - progressPercentage / 100;
  let textOpacity = textProgress;

  let columnsProgress = progressPercentage / 100;
  let columnsOpacity = columnsProgress;

  if (isMobile()) {
    return (
      <MobileContainer>
        <SubTitle>
          How we build{" "}
          <StyledSpan
            style={{
              color: progressPercentage >= 90 ? "#1473DF" : "#1F1F1F",
            }}
          >
            custom LLMs
          </StyledSpan>
        </SubTitle>
        <Gap height="40px" />
        <GridGraphic width="100%" height="100%" progressPercentage={90} />
        <Columns
          style={{
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          {messages?.map((message, i) => (
            <Column key={i}>
              {message.icon}
              <ColumnTitle dangerouslySetInnerHTML={{ __html: message?.title }} />
              <div>{message.text}</div>
            </Column>
          ))}
        </Columns>
      </MobileContainer>
    );
  }

  return (
    <LongContainer ref={containerRef}>
      <Container style={{ position: hasReachedTop ? "fixed" : "relative", opacity: 1, ...bottomStyles }}>
        <StyledMonoText style={{ ...TOP_LEFT_TEXT, opacity: textOpacity, textAlign: "right" }}>
          {messages?.[0]?.title}
        </StyledMonoText>
        <StyledMonoText style={{ ...TOP_RIGHT_TEXT, opacity: textOpacity }}>{messages?.[1]?.title}</StyledMonoText>
        <StyledMonoText style={{ ...BOTTOM_TEXT, opacity: textOpacity }}>{messages?.[2]?.title} </StyledMonoText>

        <SubTitle>
          How we build{" "}
          <StyledSpan
            style={{
              color: progressPercentage >= 90 ? "#1473DF" : "#1F1F1F",
            }}
          >
            custom LLMs
          </StyledSpan>
        </SubTitle>
        <GridGraphic progressPercentage={progressPercentage} />
        <Columns
          style={{
            marginTop: 10,
            opacity: columnsOpacity,
          }}
        >
          {messages?.map((message, i) => (
            <Column key={i}>
              {message.icon}
              <ColumnTitle dangerouslySetInnerHTML={{ __html: message?.title }} />
              <div>{message.text}</div>
            </Column>
          ))}
        </Columns>
      </Container>
    </LongContainer>
  );
};

export default MessagesV3Animated;
