import BannerV3 from "components/BannerV3";
import Benchmarks from "components/Benchmarks";
import DarkBannerV3 from "components/DarkBannerV3";
import FooterV3 from "components/FooterV3";
import HeroV3 from "components/HeroV3";
import MessagesV3 from "components/MessagesV3";
import MessagesV3Animated from "components/MessagesV3Animated";
import MessagesWithGraphicV3 from "components/MessagesWithGraphicV3";
import NavFloating from "components/NavFloating";
import SelectorSectionV3 from "components/SelectorSectionV3";
import {
  ChatBotsGraphic,
  CoPilotGraphic,
  EmailGraphic,
  GenerativeDataScienceGraphic,
  VideoOnGrid,
  WorkflowGraphic,
  WorkflowGraphicMobile,
} from "components/v3/graphics";
import {
  LShapeIcon,
  SpaceShipIcon,
  TShapeIcon,
  TickInCircleIcon,
  TwoDiagonalArrowsIcon,
  ZShapeIcon,
} from "components/v3/icons";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

export const CENTER_COLUMN_WIDTH = 1200;
export const COLOR1 = "#38D0DA";
export const COLOR2 = "#497FF3";
export const BORDER_TOP_GREY = "#9b9b9b";

export const GRADIENT_TEXT = `
background: linear-gradient(88.57deg, ${COLOR2} 17.21%, ${COLOR1} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const GRADIENT_TEXT_FLIPPED = `
background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const SIDE_MARGIN = 150;

const SERVICE_0 = "Data Extraction";
const SERVICE_1 = "Data Analytics";
const SERVICE_2 = "Agentic Automation";

const SECTIONS = [
  {
    render: () => <NavFloating key={uuidv4()} />,
  },
  {
    render: props => <HeroV3 key={uuidv4()} {...props} />,
    props: {
      title: "<span class='blue-highlight'>Custom LLMs</span><br/>aligned with your business.",
      subTitle:
        "Customised <span class='black-highlight'>models.</span> Customised <span class='black-highlight'>solutions.</span> Owned <span class='black-highlight'>privately.</span>",
      buttonText: "Talk to us",
      buttonHref: "/",
      videoSrc: "/images/squares.mp4",
      logoSrcs: [
        "/images/google-cloud-logo.png",
        "/images/logos/aws-logo.png",
        "/images/logos/tpicap-logo.png",
        "/images/logos/liquidnet-logo.png",
      ],
    },
  },
  {
    render: props => <DarkBannerV3 key={uuidv4()} {...props} />,
    props: {
      title:
        "Why fit yourself into others’ LLM,<br/>when you can <span class='blue-highlight'>customise an LLM</span> to fit you?",
    },
  },
  // {
  //   render: props => <TestimonialsV3 key={uuidv4()} {...props} />,
  //   props: {
  //     title: "What our clients say",
  //     smallSections: [
  //       {
  //         title: "TPICAP",
  //         navTitle: "Chat API",
  //         imageSrc: "/images/logos/tpicap-logo.png",
  //         backgroundImgSrc: BGS[4],
  //         description:
  //           "Our partnership with Boltzbit Custom LLM is a reflection of that strategy to bring true efficiency to bond primary markets.",
  //         author: "COO Mark",
  //       },
  //     ],
  //   },
  // },
  {
    render: props => <MessagesV3Animated key={uuidv4()} {...props} />,
    props: {
      title: "How we build <span class='blue-highlight'>custom LLMs</span>",
      messages: [
        {
          icon: <LShapeIcon />,
          title: "Universal data source",
          text: "Merge public internet data with proprietary sources for a comprehensive dataset.",
        },
        {
          icon: <ZShapeIcon />,
          title: "Agentic LLM",
          text: "LLMs with a transparent chain of thought that users can customise.",
        },
        {
          icon: <TShapeIcon />,
          title: "No code solution",
          text: "Customise LLMs for your workflow without writing a single line of code.",
        },
      ],
    },
  },
  {
    render: props => <SelectorSectionV3 key={uuidv4()} {...props} />,
    props: {
      title: "Our Enterprise Solutions",
      description: "Providing unmatched accuracy.",
      navTags: ["Generic", "Sell-Side", "Buy-Side", "Hedge Funds", "FinTech"],
      options: [
        {
          title: SERVICE_0,
          content: <EmailGraphic />,
        },
        {
          title: SERVICE_1,
          content: <GenerativeDataScienceGraphic />,
        },
        {
          title: SERVICE_2,
          content: <ChatBotsGraphic />,
        },
      ],
      options1: [
        {
          subTitle: SERVICE_0,
          title: "Client Intelligence",
          content: <EmailGraphic />,
        },
        {
          subTitle: SERVICE_1,
          title: "Assets Pricing",
          content: <GenerativeDataScienceGraphic />,
        },
        {
          subTitle: SERVICE_2,
          title: "Predictive Outreach",
          content: <ChatBotsGraphic />,
        },
      ],
      options2: [
        {
          subTitle: SERVICE_0,
          title: "Primary Markets",
          content: <EmailGraphic />,
        },
        {
          subTitle: SERVICE_1,
          title: "Counterparty Profiling",
          content: <GenerativeDataScienceGraphic />,
        },
        {
          subTitle: SERVICE_2,
          title: "Price Formation",
          content: <ChatBotsGraphic />,
        },
      ],
      options3: [
        {
          subTitle: SERVICE_0,
          title: "Research Aggregation",
          content: <EmailGraphic />,
        },
        {
          subTitle: SERVICE_1,
          title: "Documents Q&A",
          content: <GenerativeDataScienceGraphic />,
        },
        {
          subTitle: SERVICE_2,
          title: "Synthetic Data ",
          content: <ChatBotsGraphic />,
        },
      ],
      options4: [
        {
          subTitle: SERVICE_0,
          title: "Document Ingestion",
          content: <EmailGraphic />,
        },
        {
          subTitle: SERVICE_1,
          title: "NLP Dashboards",
          content: <GenerativeDataScienceGraphic />,
        },
        {
          subTitle: SERVICE_2,
          title: "Reports Automation",
          content: <ChatBotsGraphic />,
        },
      ],
    },
  },
  {
    render: props => <MessagesWithGraphicV3 key={uuidv4()} {...props} />,
    props: {
      title: "Your AI agent.<br />Your way of thinking.",
      description:
        "We build models you can trust. Deliver traceable, accurate insights with full control over your data analysis.",
      graphic: <WorkflowGraphic />,
      graphicMobile: <WorkflowGraphicMobile />,
      messages: [
        {
          icon: <TickInCircleIcon />,
          title: "Guaranteed results",
          text: "Custom LLMs can master specific tasks with unmatched accuracy.",
        },
        {
          icon: <TwoDiagonalArrowsIcon />,
          title: "Highly scalable",
          text: "Use Boltzbit's architecture for cost-efficient, scalable custom LLMs.",
        },
        {
          icon: <SpaceShipIcon />,
          title: "Ahead of competition",
          text: "Unlike third-party LLMs, custom LLMs are client-owned proprietary IP.",
        },
      ],
    },
  },
  {
    render: props => <Benchmarks key={uuidv4()} {...props} />,
    props: {
      title: "What makes us unique?",
      description: "Check out our unprecedented benchmarks (R&D).",
      benchmarks: [
        {
          value: 21,
          suffix: "x",
          title: "Faster",
          description: "Creating concise answers at unprecedented speed without high-end GPUs.",
        },
        {
          value: 98,
          suffix: "%",
          title: "Accuracy",
          description: "Generating sequences of tokens provides the best answers for your needs.",
        },
      ],
    },
  },
  {
    render: props => <BannerV3 key={uuidv4()} {...props} />,
    props: {
      title: "Stay ahead of the curve with your own GenAI",
      // description: "Short text about CTA.",
    },
  },
  {
    render: props => <FooterV3 />,
  },
];

const DesktopContainer = styled.div`
  font-family: Alexandria;
  font-size: 16px;
  font-weight: 300;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Container = styled.div``;

const MainPageV3 = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token") === "bzabc") {
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, [searchParams.get("token")]);

  const token = searchParams?.get("token") || localStorage.getItem("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>{SECTIONS?.map(({ render, props }) => render(props))}</DesktopContainer>
    </Container>
  );
};

export default MainPageV3;
