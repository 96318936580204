import ContactUsModal from "components/ContactUsModal";
import { ButtonLink, MonoText, Title } from "components/v3/shared";
import styled from "styled-components";

const SIDE_MARGIN = 150;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  padding-left: ${SIDE_MARGIN}px;
  height: 100vh;
  align-items: center;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 0 20px;
    padding-top: 100px;
    height: auto;
    min-height: 100vh;
    grid-template-columns: 1fr;
  }
`;

const Left = styled.div`
  display: grid;
  gap: 40px;
`;

const Right = styled.div`
  display: grid;
  justify-self: end;
  align-self: start;
  overflow: hidden;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const Logos = styled.div`
  padding: 33px 0;
  border-top: 1px solid #d9d9d9;
  grid-column: span 2;

  display: flex;
  gap: 64px;
  align-items: center;
  justify-content: center;
  margin-right: ${SIDE_MARGIN}px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 20px;
    margin-right: 0;
    overflow-x: auto;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px 0;
  }
`;

const Video = styled.video`
  height: 80vh;
  z-index: -1;
  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    height: 60vh;
    opacity: 0.5;
  }
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  position: relative;
  top: -2px;
`;

const StyledMonoText = styled(MonoText)`
  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    font-size: 12px;
  }
`;

const LogoImg = styled.img`
  height: 24px;
  filter: grayscale(100%);
`;

const RainbowText = styled.span`
  background: linear-gradient(90deg, #30c77b 0%, #42968d 34%, #4c63c8 67.5%, #e14649 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  line-height: 1.5;
`;

const HeroV3 = ({ title, buttonHref, subTitle, buttonText, videoSrc, logoSrcs = [] }) => {
  return (
    <Container>
      <Left>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <MonoText style={{ lineHeight: 2 }} dangerouslySetInnerHTML={{ __html: subTitle }} />
        {/* <ContactUsModal triggerComponent={<ButtonLink>{buttonText}</ButtonLink>} /> */}
        <ButtonLink target="_blank" href="https://flow.boltzbit.com">
          <RainbowText>Free chat</RainbowText>
        </ButtonLink>
      </Left>
      <Right>
        <Video autoPlay muted loop src={videoSrc} />
        <HorizontalLine />
      </Right>
      <Logos>
        <StyledMonoText style={{ whiteSpace: "nowrap" }}>Trusted by</StyledMonoText>
        {logoSrcs.map((src, i) => {
          let style = {};
          if (i === 2) {
            style = { height: 42 };
          }

          return <LogoImg key={i} src={src} style={style} />;
        })}
      </Logos>
    </Container>
  );
};

export default HeroV3;
